import HomeFrameBorder from "./HomeFrameBorder";
import Home from "./Home";
import Exposition from "./Exposition";
import React, {createRef} from "react";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import Hammer from "hammerjs";
import {gsap} from "gsap";

export default class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.currentVertical = new BoundariesCounter(3, 1, 5);
        this.currentHorizontal = new BoundariesCounter(3, 1, 5);
        this.galleryRoom = createRef();
        this.galleryRoomActual = null;
        gsap.registerPlugin(ScrollToPlugin);
    }

    componentDidMount = () => {
        this.galleryRoomActual = this.galleryRoom.current;
        this.registerSwipe(this.galleryRoom.current);
    };

    registerSwipe = (scene) => {
        const manager = new Hammer.Manager(scene);
        const Swipe = new Hammer.Swipe({direction: Hammer.DIRECTION_ALL});
        const Pinch = new Hammer.Pinch();
        manager.add(Swipe);
        manager.add(Pinch);
        manager.on('swipe', (e) => {
            if (this.inRange(e.angle, 135, 180) || this.inRange(e.angle, -180, -135)) {
                this.currentHorizontal.increment();
            } else if (this.inRange(e.angle, -45, 45)) {
                this.currentHorizontal.decrement();
            } else if (this.inRange(e.angle, 45, 135)) {
                this.currentVertical.decrement();
            } else if (this.inRange(e.angle, -135, -45)) {
                this.currentVertical.increment();
            }
            this.scroll();
        });
        manager.on('pinchend', () => {
            scene.classList.toggle('zoom-in');
        });
        document.addEventListener('keyup', event => {
                if (event.key === ' ') {
                    scene.classList.toggle('zoom-in')
                    return;
                }
                if ([38, 40, 37, 39].includes(event.keyCode)) {
                    console.log(1);
                    if (event.keyCode === 38) {
                        this.currentVertical.decrement();
                    } else if (event.keyCode === 40) {
                        this.currentVertical.increment();
                    } else if (event.keyCode === 37) {
                        this.currentHorizontal.decrement();
                    } else if (event.keyCode === 39) {
                        this.currentHorizontal.increment();
                    }
                    this.scroll();
                }
            }
        );
        window.screen.orientation.addEventListener('change', (e) => {
            if (e.type === 'landscape-primary') {
                this.currentHorizontal = new BoundariesCounter(6 - this.currentHorizontal.get(), 1, 5);
            } else {
                this.currentVertical = new BoundariesCounter(6 - this.currentVertical.get(), 1, 5);
            }
            this.scroll();
        });
    }

    inRange(a, b, c) {
        return a >= b && a <= c;
    }

    scroll = () => {
        console.log(this.currentVertical.get(), this.currentHorizontal.get());
        this.galleryRoomActual.style.transform = `translate(calc(${-(this.currentHorizontal.get() - 3)} * (var(--exposition-width-1) + var(--frame-size))), calc(${-(this.currentVertical.get() - 3)} * (var(--exposition-height-1) + var(--frame-size))))`;
    }

    render() {
        return <div ref={this.galleryRoom} className={"gallery-room"}>
            <div className={"gallery"}>
                <div className={"home-frame home-frame-top"}>
                    <HomeFrameBorder
                        className={"home-frame-border home-frame-border-horizontal home-frame-border-top"}/>
                </div>
                <div className={"home-frame home-frame-bottom"}>
                    <HomeFrameBorder
                        className={"home-frame-border home-frame-border-horizontal home-frame-border-bottom"}/>
                </div>
                <div className={"home-frame home-frame-left"}>
                    <HomeFrameBorder className={"home-frame-border home-frame-border-vertical home-frame-border-left"}/>
                </div>
                <div className={"home-frame home-frame-right"}>
                    <HomeFrameBorder
                        className={"home-frame-border home-frame-border-vertical home-frame-border-right"}/>
                </div>
                <div className={"exposition-1-1"} id={'exposition1'}><Home/></div>
                <div className={"border-horizontal-1"}/>
                <div className={"border-vertical-2"}/>
                <div className={"border-horizontal-1"}/>
                <div className={"border-horizontal-2"}/>
                <div className={"exposition-1-2"} id={'exposition2'}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-1-3"} id={'exposition3'}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/3_512.gif"} caption={"Fast 3"}/></div>
                <div className={"exposition-1-4"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/4_512.gif"} caption={"Fast 4"}/></div>
                <div className={"exposition-1-5"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/5_512.gif"} caption={"Fast 5"}/></div>
                <div className={"exposition-2-1"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/6_512.gif"} caption={"Fast 6"}/></div>
                <div className={"exposition-2-2"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/7_512.gif"} caption={"Fast 7"}/></div>
                <div className={"exposition-2-3"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-2-4"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-2-5"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-3-1"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-3-2"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-3-3"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-3-4"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-3-5"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-4-1"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-4-2"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-4-3"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-4-4"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-4-5"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-5-1"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-5-2"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-5-3"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-5-4"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
                <div className={"exposition-5-5"}><Exposition
                    img={"https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/2_512.gif"} caption={"Fast 2"}/></div>
            </div>
        </div>
    }
}

class BoundariesCounter {
    i;
    small;
    big;

    constructor(initial, small, big) {
        this.i = initial;
        this.small = small;
        this.big = big;
    }

    increment() {
        ++this.i;
        this.i = Math.min(this.i, this.big);
    }

    decrement() {
        --this.i;
        this.i = Math.max(this.i, this.small);
    }

    get() {
        return this.i;
    }
}
