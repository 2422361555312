import './styles/Home.scss';
import './styles/TextGlitch.scss';
import React, {createRef} from "react";
import Typed from "typed.js";
import Exposition from "./Exposition";

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.greetingsRef = createRef();
        this.home = createRef();
    }

    componentDidMount = () => {
        this.greatings(this.greetingsRef.current, [
            "Hellow it's nice to meet YOU!!!"
        ])
    };

    greatings = (el, text) => {
        return new Typed(el, {
            strings: text,
            typeSpeed: 50,
            backSpeed: 15,
            cursorChar: '',
        });
    }

    render() {
        return (
            <div ref={this.home} className={"home"}>
                <Exposition logo={true} img={'https://pub-c3e3f5794f94421cbcc2d73487f39267.r2.dev/1_512.gif'}
                            caption={'Fast'}>
                </Exposition>
                <div className={"greetings"}>
                    <h2><a className={"box"} ref={this.greetingsRef}
                           href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">&nbsp;</a></h2>
                </div>
            </div>
        );
    }

}

export default Home;
