import React from "react";
import Typed from "./Typed";
import "./styles/HomeFrame.scss"

class HomeFrameBorder extends React.Component {

    zeroOne = "⟁111".repeat(100);

    render() {
        return <div className={"home-frame-border " + this.props.className}>
            <div><Typed
                className={"home-frame-border-text glitch-text-rare home-frame-border-text-1"}
                typeSpeed={1}
                title={[
                    "1".repeat(1000) + this.zeroOne.shuffle(),
                ]}
            /></div>
            <div><Typed
                className={"home-frame-border-text glitch-text-rare home-frame-border-text-2"}
                typeSpeed={10}
                title={[
                    "⟁".repeat(500) + this.zeroOne.shuffle(),
                ]}
            /></div>
            <div><Typed
                className={"home-frame-border-text glitch-text-rare home-frame-border-text-3"}
                typeSpeed={10}
                title={[
                    "M".repeat(500) + "█".repeat(200),
                ]}
            /></div>
        </div>
    }
}

export default HomeFrameBorder;
